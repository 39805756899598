import {
  GlobeAltIcon,
  CheckCircleIcon,
  TableCellsIcon,
  PresentationChartLineIcon,
} from '@heroicons/react/24/outline';

export const navigation = [
  { name: 'Productos', href: '/' },
  { name: 'Características', href: '/' },
  { name: 'Marketplace', href: '/' },
  { name: 'Empresa', href: '/' },
];

export const features = [
  {
    name: 'Sitio web',
    description:
      'Incrementa el número de tus pacientes y tu tráfico en internet.',
    icon: GlobeAltIcon,
  },
  {
    name: 'Simplifica citas y pagos.',
    description:
      'Administra citas, servicios y transacciones en un mismo sitio.',
    icon: CheckCircleIcon,
  },
  {
    name: 'Obtén datos clave',
    description:
      'Accesa rapido a reportes para tener cada vez más productividad.',
    icon: TableCellsIcon,
  },
  {
    name: 'Llega a más pacientes',
    description:
      'Encuentra más pacientes con SEO, redes sociales & sugerencias de contenido.',
    icon: PresentationChartLineIcon,
  },
];

export const tiers = [
  {
    name: 'Básico',
    id: 'tier-freelancer',
    href: '#',
    priceMonthly: '$599 MXN',
    description: 'Página web estática personalizada, con los colores de tu marca, logo.',
    features: ['Página web', 'Propuesta colores', 'Propuesta logo', 'Dominio medflix'],
    // features: ['5 products', 'Up to 1,000 subscribers', 'Basic analytics', '48-hour support response time'],
    mostPopular: false,
  },
  {
    name: 'Personal',
    id: 'tier-startup',
    href: '#',
    priceMonthly: '$999 MXN',
    description: 'Obtén la libertad para customizar tu sitio. Cambia textos, colores, imágenes, y administra tus dominios.',
    features: [
      'Gestor de contenido',
      '1 Dominio personalizado.',
      'SEO básico.',
      'Analítica básica.',
    ],
    mostPopular: true,
  },
  {
    name: 'Crecimiento',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$1,999 MXN',
    description: 'Administra tu calendario de citas, cobra de manera digital y por adelantado, con reglas customizadas y penalizaciones por cancelación.',
    features: [
      'Sistema de citas.',
      'Pagos con tarjeta.',
      'Reglas de cancelación.',
      'Recordatorios',
    ],
    mostPopular: false,
  },
];

export const faqs = [
  {
    id: 1,
    question: "¿Puedo cambiar de plan después de elegir uno?",
    answer:
      "Sí, puedes subir o bajar de categoría, así como cancelar el plan, cuando así lo desees.",
  },
];

export const footerNavigation = {
  solutions: [
    { name: 'Hosting', href: '/' },
    { name: 'Envíos de correos', href: '/' },
    { name: 'Pagos en línea', href: '/' },
    { name: 'Hospitales', href: '/' },
  ],
  support: [
    { name: 'Precios', href: '/' },
    { name: 'Manuales', href: '/' },
    { name: 'Tickets', href: '/' },
  ],
  company: [
    { name: 'Acerca de', href: '/' },
    { name: 'Blog', href: '/' },
    { name: 'Carrera', href: '/' },
  ],
  legal: [
    { name: 'Privacidad', href: '/' },
    { name: 'Términos', href: '/' },
  ],
};
