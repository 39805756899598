import Header from './components/header';
import Footer from './components/footer';
import Hero from './components/hero/Hero';
// import Companies from './components/companies/Companies';
import Feature from './components/feature/Feature';
// import Testimonials from './components/testimonials/Testimonials';
import Pricing from './components/pricing/Pricing';
import Faqs from './components/faqs/Faqs';
import Cta from './components/cta/Cta';

const App = () => {

  return (
    <div className="bg-white">
      <Header />
      <main className="isolate">
        {/* Hero section */}
        <Hero />
        {/* Logo cloud */}
        {/* <Companies /> */}
        {/* Feature section */}
        <Feature />
        {/* Testimonial section */}
        {/* <Testimonials /> */}
        {/* Pricing section */}
        <Pricing />
        {/* FAQs */}
        <Faqs />
        {/* CTA section */}
        <Cta />
      </main>
      <Footer />
    </div>
  )
};

export default App;
